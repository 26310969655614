import React from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    TimeScale,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import "chartjs-adapter-moment";
import moment from "moment";
import { colors, converteParaHorarioLocal, irradianciasMap } from "../utils/utils";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    TimeScale,
    Title,
    Tooltip,
    Legend
);

export default function GraficoEstacao({ estacao, fullScreen, date, startDate, endDate }) {
    const options = {
        radius: 3, // desabilitar pontos
        locale: 'pt-br',
        responsive: true,
        scales: {
            x: {
                grid: {
                    display: false,
                },
                type: "time",
                time: {
                    unit: "hour",
                    displayFormats: {
                        hour: "HH:00",
                    },
                },
                min: moment(new Date(
                    fullScreen == 12 && startDate ? startDate.year : date.year,
                    fullScreen == 12 && startDate ? startDate.month - 1 : date.month - 1,
                    fullScreen == 12 && startDate ? startDate.day : date.day, 5, 0, 0)),
                max: moment(new Date(
                    fullScreen == 12 && endDate ? endDate.year : date.year,
                    fullScreen == 12 && endDate ? endDate.month - 1 : date.month - 1,
                    fullScreen == 12 && endDate ? endDate.day : date.day, 20, 0, 0)),
            },
            A: {
                type: "linear",
                position: "left",
                title: {
                    display: true,
                    text: "Temperatura (ºC)",
                },
            },
            B: {
                type: "linear",
                position: "right",
                grid: {
                    display: false,
                },
                title: {
                    display: true,
                    text: "Irradiância (W/m²)",
                },
            },
        },
        tension: 0.4,
    };

    const data = {
        labels: estacao?.map((d) => converteParaHorarioLocal(d.data)),
        datasets: [
            {
                label: "Temperatura",
                yAxisID: "A",
                fill: true,
                data: estacao?.map((e) => e.temperatura_ar),
                borderColor: "rgb(255, 99, 132)",
                backgroundColor: "rgba(255, 99, 132, 0.5)",
            },
        ],
    };

    estacao[0]?.estacaoDnis?.forEach((item, index) => {
        data.datasets.push({
            label: `Irradiância ${irradianciasMap[index + 1]}`,
            yAxisID: "B",

            fill: true,
            data: estacao?.map(
                (e) => e.estacaoDnis?.find((item) => item.cod == index + 1)?.valor
            ),
            borderColor: colors[index].borderColor,
            backgroundColor: colors[index].backgroundColor,
        });
    });

    return <Line options={options} data={data} />;
}
