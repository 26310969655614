import React from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    TimeScale,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import "chartjs-adapter-moment";
import moment from "moment";
import { converteParaHorarioLocal } from "../utils/utils";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    TimeScale,
    Title,
    Tooltip,
    Legend
);

export default function GraficoGeracaoDiaria({ energiaMedidor, geracaoDiaria, fullScreen, date, startDate, endDate }) {
    console.log(energiaMedidor, geracaoDiaria)
    const options = {
        radius: 3, // desabilitar pontos
        locale: 'pt-br',
        responsive: true,
        scales: {
            x: {
                grid: {
                    display: false,
                },
                type: "time",
                time: {
                    unit: "hour",
                    displayFormats: {
                        hour: "HH:00",
                    },
                },
                min: moment(new Date(
                    fullScreen == 12 && startDate ? startDate.year : date.year,
                    fullScreen == 12 && startDate ? startDate.month - 1 : date.month - 1,
                    fullScreen == 12 && startDate ? startDate.day : date.day, 5, 0, 0)),
                max: moment(new Date(
                    fullScreen == 12 && endDate ? endDate.year : date.year,
                    fullScreen == 12 && endDate ? endDate.month - 1 : date.month - 1,
                    fullScreen == 12 && endDate ? endDate.day : date.day, 20, 0, 0)),
            },
            secondXAxis: {
                axis: 'x',
                labels: geracaoDiaria.map((g) => converteParaHorarioLocal(g.data)),
                grid: {
                    display: false,
                },
                type: "time",
                display: false,
                min: moment(new Date(date.year, date.month - 1, date.day, 5, 0, 0)),
                max: moment(new Date(date.year, date.month - 1, date.day, 20, 0, 0)),
            },
            y: {
                title: {
                    display: true,
                    text: "Potência Ativa (kW)",
                },
            },
        },
        tension: 0.4,
    };

    const data = {

        labels: energiaMedidor.map((e) => converteParaHorarioLocal(e.data)),
        datasets: [
            {
                label: "Potência dos Inversores",
                data: geracaoDiaria?.map((g) => g.energia),
                xAxisID: "secondXAxis",
                borderColor: "rgb(255, 99, 132)",
                backgroundColor: "rgba(255, 99, 132, 0.5)",
            },
            {
                label: "Potência do Medidor",
                data: energiaMedidor?.map((g) => g.potencia),
                xAxisID: "x",
                borderColor: "rgb(153, 0, 204)",
                backgroundColor: "rgb(153, 0, 204, 0.5)",
            },
        ],
    };

    console.log(data)

    return <Line options={options} data={data} />;
}
