import React from "react";
import { Slide } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import AppProvider from "./hooks";
import Global from "./pages/Global";
import InsertTokenRecoverPassword from "./pages/InsertTokenRecoverPassword";
import Login from "./pages/Login";
import RecoverPassword from "./pages/RecoverPassword";
import RecoverUser from "./pages/RecoverUser";
import RecoveryChangePassword from "./pages/RecoveryChangePassword";
import { isAuthenticated } from "./services/authService";
import RelatorioMensal from "./pages/RelatorioMensal";
function App() {
    const ProtectedRoute = ({ children }) => {
        if (!isAuthenticated()) {
            return <Navigate to="/login" replace />;
        }

        return children;
    };

    return (
        <>
            <SnackbarProvider
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                TransitionComponent={Slide}
            >
                <AppProvider>
                    <Routes>
                        <Route
                            path="/:page"
                            element={
                                <ProtectedRoute>
                                    <Global />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/:page/:usinaId"
                            element={
                                <ProtectedRoute>
                                    <Global />
                                </ProtectedRoute>
                            }
                        />
                        <Route path="*" element={<Navigate to={{ pathname: "/home" }} />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/relatorio_mensal" element={<RelatorioMensal />} />
                        <Route path="/recover_password" element={<RecoverPassword />} />
                        <Route path="/insert_token" element={<InsertTokenRecoverPassword />} />
                        <Route path="/change_password" element={<RecoveryChangePassword />} />
                        <Route path="/recover_user" element={<RecoverUser />} />
                    </Routes>
                </AppProvider>
            </SnackbarProvider>
        </>
    );
}

export default App;
