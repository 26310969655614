import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    TimeScale,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { colors, converteParaHorarioLocal, getRandomColors, irradianciasMap } from "../utils/utils";
import { Line } from "react-chartjs-2";
import "chartjs-adapter-moment";
import moment from "moment";
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    TimeScale,
    Title,
    Tooltip,
    Legend
);

export default function GenericInversorChart({ selectLabel, randomColors, data, date, showIrradiation, irradiation }) {

    let options = {
        radius: 0, // desabilitar pontos
        locale: 'pt-br',
        responsive: true,
        scales: {
            x: {
                grid: {
                    display: false,
                },
                type: "time",
                time: {
                    unit: "hour",
                    displayFormats: {
                        hour: "HH:00",
                    },
                },
                min: moment(new Date(date.year, date.month - 1, date.day, 5, 0, 0)),
                max: moment(new Date(date.year, date.month - 1, date.day, 20, 0, 0)),
            },
            secondXAxis: {
                axis: 'x',
                labels: irradiation?.map((g) => converteParaHorarioLocal(g.data)),
                grid: {
                    display: false,
                },
                type: "time",
                display: false,
                min: moment(new Date(date.year, date.month - 1, date.day, 5, 0, 0)),
                max: moment(new Date(date.year, date.month - 1, date.day, 20, 0, 0)),
            },
            A: {
                type: "linear",
                position: "left",
                title: {
                    display: true,
                    text: `${selectLabel.label}`,

                },
            },
            B: {
                type: "linear",
                position: "right",
                grid: {
                    display: false,
                },
                title: {
                    display: true,
                    text: `${showIrradiation ? "Irradiância (W/m²)" : ""}`,
                },
            },
        },
        tension: 0.4,
    };

    const plottedData = {
        labels: [],
        datasets: []
    };
    data?.forEach((item, index) => {
        if (data[index][0]?.label != undefined) {
            let labels = data[index].map((d) => converteParaHorarioLocal(d?.date))
            plottedData.datasets.push({
                label: `inversor ${data[index][0]?.label}`,
                yAxisID: "A",
                xAxisID: "x",
                fill: true,
                data: data[index].map((e) => e.value),
                borderColor: randomColors[index].borderColor,
                backgroundColor: randomColors[index].backgroundColor,
                labelsA: labels
            });

            labels.forEach((label) => {
                if (!plottedData.labels.includes(label)) {
                    plottedData.labels.push(label);
                }
            });
        }
    })

    if (data[0]?.potencia != undefined) {
        let labels = data.map((d) => converteParaHorarioLocal(d?.data))
        plottedData.datasets.push({
            label: `Potência Ativa - Medidor`,
            yAxisID: "A",
            xAxisID: "x",
            fill: true,
            data: data.map((d) => d.potencia),
            borderColor: randomColors[8].borderColor,
            backgroundColor: randomColors[8].backgroundColor,
            pointRadius: 4,
        });

        labels.forEach((label) => {
            if (!plottedData.labels.includes(label)) {
                plottedData.labels.push(label);
            }
        });
    }

    if (showIrradiation) {
        irradiation[0]?.estacaoDnis?.forEach((item, index) => {
            plottedData.datasets.push({
                label: `Irradiância ${irradianciasMap[index + 1]}`,
                yAxisID: "B",
                xAxisID: "secondXAxis",
                fill: true,
                data: irradiation?.map(
                    (e) => e.estacaoDnis.find((item) => item.cod == index + 1)?.valor
                ),
                borderColor: colors[index].borderColor,
                backgroundColor: colors[index].backgroundColor,
                pointRadius: 4, // Radius of the point

            });

        });
    }


    return <Line options={options} data={plottedData} />;
}
