import React, { useState, useEffect } from "react";
import moment from "moment";
import "../styles/table.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { InversorStatus } from "../styles/InversoresStyle";
import * as inversoresService from "../services/inversoresService";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Row, Col } from "react-bootstrap";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import ModeStandbyOutlinedIcon from "@mui/icons-material/ModeStandbyOutlined";
import CloudOffOutlinedIcon from '@mui/icons-material/CloudOffOutlined';
import ModelTrainingOutlinedIcon from "@mui/icons-material/ModelTrainingOutlined";
import { converteParaHorarioLocal } from "../utils/utils";

export default function StatusInversores({ usinaId }) {
    const [statusInv, setStatusInv] = useState([]);
    const horaAtual = moment(new Date, "YYYY-MM-DDTHH:mm:ss")
    useEffect(() => {
        getInversorData();
    }, []);

    function responseTimeHandle(responseData) {
        let responseConverted = moment(new Date(converteParaHorarioLocal(responseData)), "YYYY-MM-DDTHH:mm:ss")
        let diff = horaAtual.diff(responseConverted, 'minutes')

        return diff
    }

    async function getInversorData() {
        try {
            let statusInversor = await inversoresService.findAllLastRegister(usinaId);
            statusInversor = groupColumns(statusInversor);
            setStatusInv(statusInversor);
        } catch (error) {
            // setError(error.message);
        }
    }

    function groupColumns(listInversores) {
        const newRows = [];
        const step = 4;

        for (let index = 0; index < listInversores.length; index + step) {
            newRows.push(listInversores.splice(index, index + step));
        }

        return newRows;
    }

    return (
        <table
            style={{
                borderCollapse: "collapse",
                width: "100%",
                padding: "11px",
                marginTop: "29px",
            }}
        >
            {statusInv.map((row, i) => (
                <Row className="rowInv" key={i}>
                    {row.map((inv, index) => (
                        <Col
                            className={
                                row.length < 4 && row.length === index + 1
                                    ? "lastColumn"
                                    : "columnInv"
                            }
                            key={index}
                            style={{
                                display: "flex",
                                height: "90px",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                            }}
                            sm={3}
                        >
                            <div>
                                <InversorStatus style={{ textAlign: "center" }}>
                                    Inversor {inv?.cod.toString().padStart(2, "0")}
                                </InversorStatus>
                            </div>
                            {inv.status === 0 && responseTimeHandle(inv?.data) <= 20 ? (
                                <span
                                    style={{
                                        fontWeight: 700,
                                        fontSize: "18px",
                                        letterSpacing: "0.02rem",
                                        color: "#049B5F",
                                    }}
                                >
                                    Ativo
                                    <CheckCircleOutlineIcon style={{ color: "#049B5F" }} />
                                </span>
                            ) : inv.status === 5120 && responseTimeHandle(inv?.data) <= 20 ? (
                                <span
                                    style={{
                                        fontWeight: 700,
                                        fontSize: "18px",
                                        letterSpacing: "0.02rem",
                                        color: "#FBB041",
                                    }}
                                >
                                    Stand By
                                    <ModeStandbyOutlinedIcon style={{ color: "#FBB041" }} />
                                </span>
                            ) : inv.status === 5632 && responseTimeHandle(inv?.data) <= 20 ? (
                                <span
                                    style={{
                                        fontWeight: 700,
                                        fontSize: "18px",
                                        letterSpacing: "0.02rem",
                                        color: "#3C96C6",
                                    }}
                                >
                                    Iniciando
                                    <ModelTrainingOutlinedIcon style={{ color: "#3C96C6" }} />
                                </span>
                            ) : inv.status === 21760 && responseTimeHandle(inv?.data) <= 20 ? (
                                <span
                                    style={{
                                        fontWeight: 700,
                                        fontSize: "18px",
                                        letterSpacing: "0.02rem",
                                        color: "#D40707",
                                    }}
                                >
                                    Falha
                                    <CancelOutlinedIcon style={{ color: "#D40707" }} />
                                </span>
                            ) : (
                                <span
                                    style={{
                                        fontWeight: 700,
                                        fontSize: "18px",
                                        letterSpacing: "0.02rem",
                                        color: "#ff9233",
                                        paddingLeft: "1%",
                                    }}
                                >
                                    Offline
                                    <CloudOffOutlinedIcon style={{ paddingLeft: "1%", color: "#ff9233" }} />
                                </span>
                            )}
                        </Col>
                    ))}
                </Row>
            ))}
        </table>
    );
}
