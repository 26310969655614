import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    TimeScale,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import "chartjs-adapter-moment";
import moment from "moment";
import { useEffect, useState } from "react";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    TimeScale,
    Title,
    Tooltip,
    Legend
);

export default function GraficoEnergiaMensalPorDia({ geracao, chartByDay, onBarClick }) {
    const [data, setData] = useState({
        labels: [],
        datasets: [],
    });

    useEffect(() => {
        if (chartByDay) {
            const chartData = {
                labels: geracao.map((g) => g.cod.toString()),
                datasets: [
                    {
                        label: "Energia diária (kWh)",
                        data: geracao.map((g) => g.dailyEnergy),
                        borderColor: "rgb(28, 49, 255)",
                        backgroundColor: "rgba(28, 49, 255, 0.8)",
                    },
                ],
            };
            setData(chartData);
        } else {
            const chartData = {
                labels: geracao.map((g) => (moment(g.date).add('days', 1).format('YYYY-MM-DD'))),
                datasets: [
                    {
                        label: "Energia diária (kWh)",
                        data: geracao.map((g) => g.totalDayEnergy),
                        borderColor: "rgb(255, 99, 132)",
                        backgroundColor: "rgba(255, 99, 132, 0.8)",
                    },
                ],
            };
            setData(chartData);
        }
    }, [geracao, chartByDay]);

    const options = {
        radius: 3, // desabilitar pontos
        locale: 'pt-br',
        responsive: true,
        scales: {
            x: {
                grid: {
                    display: false,
                },
                type: chartByDay ? "category" : "time", // Alterado para "category" se chartByDay for verdadeiro
                time: {
                    unit: "days",
                    displayFormats: {
                        days: "DD/MM"
                    },
                },
                title: {
                    display: true,
                    text: chartByDay ? "Inversores" : "",
                },
            },
            y: {
                title: {
                    display: true,
                    text: "Energia diária (kWh)",
                },
            },
        },
        tension: 0.4,
        onClick: !chartByDay ? handleBarClick : "",
    };
    function handleBarClick(event, elements) {
        if (elements.length > 0) {
            const clickedIndex = elements[0].index;
            const clickedDate = data.labels[clickedIndex];
            onBarClick(clickedDate);
        }
    }

    return <Bar options={options} data={data} />;
}


