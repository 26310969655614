import React, { useEffect, useState } from "react";
import {
    HeaderTitle,
    DivisionLine,
    HeaderDescription,
    HeaderUpdateText,
} from "../styles/HeaderStyles";
import { Row, Col, Container } from "react-bootstrap";
import LoadingSpin from "react-loading-spin";
import { useSelector } from "react-redux";
import {
    Badge,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    InputAdornment,
    Menu,
    MenuItem,
    styled,
    TextField,
} from "@mui/material";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import EditIcon from "@mui/icons-material/Edit";
import { getUserId, removeLoginTokens } from "../services/authService";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import { getSelfUser } from "../services/userService";
import PersonIcon from "@mui/icons-material/Person";
import LockIcon from "@mui/icons-material/Lock";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { changePassword } from "../services/userService";
import * as alarmeService from "../services/alarmeService";
import { useSnackbar } from "notistack";
import { useAuth } from "../hooks/Auth";
import calendar from "../assets/calendar.png";
import vector from "../assets/vector.png";

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
        }}
        disableScrollLock={true}
        {...props}
    />
))(({ theme }) => ({
    "& .MuiPaper-root": {
        borderRadius: 4,
        // marginTop: theme.spacing(1),
        minWidth: 180,
        // marginTop: "-1px",
        border: "none",
        color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
        boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        "& .MuiMenu-list": {
            borderRadius: 4,
            border: "none",
            padding: "4px 0",
            backgroundColor: "#0a5068",
            color: "white",
        },
        "& .MuiMenuItem-root": {
            fontSize: 15,
        },
    },
}));

export default function Header() {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const [userName, setUserName] = useState("");
    const [user, setUser] = useState({});
    const counter = useSelector((state) => state.lastUpdateCounter);
    const [anchorEl, setAnchorEl] = useState(null);
    const [isOpenUserModal, setIsOpenUserModal] = useState(false);
    const [isOpenChangePasswordModal, setIsOpenChangePasswordModal] = useState(false);
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showNewConfirmationPassword, setShowNewConfirmationPassword] = useState(false);

    const [currentPassword, setCurrentPassword] = useState("");
    const [newConfirmationPassword, setNewConfirmationPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");

    const { notificationsCount, setNotificationsCount } = useAuth();

    useEffect(() => {
        getUserName();
        getAlarmNotification();

        const interval = setInterval(() => {
            getAlarmNotification();
        }, 60 * 1000);

        return () => clearInterval(interval);
    }, []);

    const getAlarmNotification = async () => {
        try {
            const response = await alarmeService.getCountNotifications();
            setNotificationsCount(response);
        } catch (error) {
            console.log("🚀 ~ file: Header.jsx ~ line 106 ~ getAlarmNotification ~ error", error);
        }
    };

    const getUserName = async () => {
        const response = await getSelfUser();
        setUser(response);
        var splittedName = response.nome.split(" ");
        if (splittedName.length > 1) {
            const firstAndLastName = splittedName[0] + " " + splittedName[splittedName.length - 1];
            setUserName(firstAndLastName);
        } else {
            setUserName(response.nome);
        }
    };

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        setAnchorEl(null);
        removeLoginTokens();
        navigate("/");
    };

    const handleOpenUserModal = () => {
        setIsOpenUserModal(true);
        setAnchorEl(null);
    };

    const handleOpenChangePassword = () => {
        setIsOpenChangePasswordModal(true);
        setAnchorEl(null);
    };

    const handleChangePassword = async () => {
        try {
            await changePassword({
                senhaAtual: currentPassword,
                senha: newPassword,
                senhaConfirmacao: newConfirmationPassword,
            });

            enqueueSnackbar("Senha alterada com sucesso!", {
                variant: "success",
            });
        } catch (error) {
            console.log("🚀 ~ file: Header.jsx ~ line 150 ~ handleChangePassword ~ error", error);
            enqueueSnackbar(error.response?.data?.message, { variant: "error" });
        }
    };

    const visualizeAlarms = () => {
        navigate("/alarmes");
        setAnchorEl(null);
    };

    return (
        <div
            style={{
                display: "flex",
                marginBottom: "1.5rem",
            }}
        >
            <nobr>
                {" "}
                {/* impede quebra de linha */}
                <HeaderTitle>Grupo Solar Região Norte</HeaderTitle>
            </nobr>
            <DivisionLine />
            <img
                src={calendar}
                width={"37px"}
                height={"32px"}
                style={{
                    margin: "-5px 0 0 30px",
                }}
            ></img>
            <Container style={{ marginTop: "-10px" }}>
                <Row>
                    <Col sm={12}>
                        <HeaderDescription>Dados mensurados em tempo real.</HeaderDescription>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} style={{ lineHeight: 0 }}>
                        <div style={{ display: "inline-flex" }}>
                            <HeaderUpdateText style={{ marginRight: "5px" }}>
                                Ultima atualização: {counter}seg
                            </HeaderUpdateText>
                            {counter > 0 ? (
                                <img style={{ height: "15px" }} src={vector} />
                            ) : (
                                <LoadingSpin size="16px" width="2px" primaryColor={"#2e64a8"} />
                            )}
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container
                style={{
                    marginTop: "-20px",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                }}
            >
                <Button
                    style={{
                        position: "absolute",
                        top: 0,
                        right: 20,
                        width: "fit-content",
                        textTransform: "capitalize",
                        backgroundColor: "#0a5068",
                        border: "none",
                        boxShadow: "4px 6px 6px rgba(0, 0, 0, 0.25)",
                        minWidth: 180,
                        textAlign: "center",

                        // borderBottom: "2px solid #0a5068",
                        // bordeRadius: "15px",
                    }}
                    id="demo-customized-button"
                    aria-controls={open ? "demo-customized-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    variant="contained"
                    disableElevation
                    onClick={handleClick}
                    endIcon={<ExpandMoreIcon />}
                >
                    {userName}
                    <Badge
                        style={{ marginLeft: 10, marginRight: 14, marginTop: 2 }}
                        color="error"
                        badgeContent={notificationsCount}
                        showZero
                    >
                        <NotificationsActiveIcon sx={{ fontSize: 18 }} />
                    </Badge>
                </Button>
                <StyledMenu
                    id="demo-customized-menu"
                    MenuListProps={{
                        "aria-labelledby": "demo-customized-button",
                        sx: { width: anchorEl && anchorEl.offsetWidth },
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    <MenuItem onClick={handleOpenUserModal} disableRipple>
                        <PersonOutlineIcon style={{ marginRight: 8, fontSize: 18 }} />
                        Painel do usuário
                    </MenuItem>
                    <MenuItem onClick={visualizeAlarms} disableRipple>
                        <NotificationsActiveOutlinedIcon style={{ marginRight: 8, fontSize: 18 }} />
                        Alarmes
                    </MenuItem>
                    <Divider sx={{ my: 0.5 }} />
                    <MenuItem onClick={handleOpenChangePassword} disableRipple>
                        <EditIcon style={{ marginRight: 8, fontSize: 18 }} />
                        Trocar senha
                    </MenuItem>
                    <MenuItem onClick={handleLogout} disableRipple>
                        <LogoutIcon style={{ marginRight: 8, fontSize: 18 }} />
                        Sair
                    </MenuItem>
                </StyledMenu>
            </Container>

            <Dialog
                maxWidth="md"
                fullWidth
                disableScrollLock={true}
                open={isOpenUserModal}
                onClose={() => setIsOpenUserModal(false)}
            >
                <DialogTitle style={{ color: "#0a5068" }}>Painel do Usuário</DialogTitle>
                <DialogContent>
                    <Divider variant="middle" sx={{ mb: 3, color: "#0a5068", padding: "0.5px" }} />
                    <Box
                        noValidate
                        component="form"
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            // m: "auto",
                            // width: "fit-content",
                        }}
                    >
                        <PersonIcon
                            sx={{ flexShrink: 0 }}
                            style={{
                                fontSize: 200,
                                marginTop: "-8px",
                                color: "#0a5068",
                                alignSelf: "center",
                                justifyContent: "center",
                            }}
                        />
                        <Box
                            noValidate
                            component="form"
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                // m: "auto",
                                width: "fit-content",
                                flexGrow: 1,
                            }}
                        >
                            <TextField
                                label="Nome"
                                id="input-nome"
                                style={{ marginBottom: "8px" }}
                                sx={{ m: 1, width: "90%" }}
                                variant="standard"
                                InputProps={{}}
                                value={user.nome}
                            />

                            <TextField
                                label="Email"
                                id="input-email"
                                sx={{ m: 1, width: "90%" }}
                                variant="standard"
                                InputProps={{}}
                                value={user.email}
                            />

                            <TextField
                                label="Cpf"
                                id="cpf-input"
                                sx={{ m: 1, width: "90%" }}
                                variant="standard"
                                InputProps={{}}
                                value={user.cpf}
                            />

                            <TextField
                                label="Perfil"
                                id="email-input"
                                sx={{ m: 1, width: "90%" }}
                                variant="standard"
                                InputProps={{}}
                                value={user.perfil}
                            />
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsOpenUserModal(false)}>Fechar</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                maxWidth="sm"
                fullWidth
                disableScrollLock={true}
                open={isOpenChangePasswordModal}
                onClose={() => setIsOpenChangePasswordModal(false)}
            >
                <DialogTitle style={{ color: "#0a5068" }}>Altere sua senha</DialogTitle>
                <DialogContent>
                    <Divider variant="middle" sx={{ mb: 3, color: "#0a5068", padding: "0.5px" }} />
                    <Box
                        noValidate
                        component="form"
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            // m: "auto",
                            // width: "fit-content",
                        }}
                    >
                        <LockIcon
                            sx={{ flexShrink: 0 }}
                            style={{
                                fontSize: 140,
                                marginTop: "-8px",
                                color: "#0a5068",
                                alignSelf: "center",
                                justifyContent: "center",
                                marginRight: "16px",
                            }}
                        />
                        <Box
                            noValidate
                            component="form"
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                // m: "auto",
                                width: "fit-content",
                                flexGrow: 1,
                            }}
                        >
                            <TextField
                                label="Senha atual"
                                id="input-nome"
                                style={{ marginBottom: "8px" }}
                                sx={{ m: 1, width: "90%" }}
                                variant="standard"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() =>
                                                    setShowCurrentPassword(!showCurrentPassword)
                                                }
                                            >
                                                {showCurrentPassword ? <FaEyeSlash /> : <FaEye />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                value={currentPassword}
                                type={showCurrentPassword ? "text" : "password"}
                                onChange={(evt) => setCurrentPassword(evt.target.value)}
                            />

                            <TextField
                                label="Nova senha"
                                id="input-email"
                                sx={{ m: 1, width: "90%" }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowNewPassword(!showNewPassword)}
                                            >
                                                {showNewPassword ? <FaEyeSlash /> : <FaEye />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={(evt) => setNewPassword(evt.target.value)}
                                variant="standard"
                                type={showNewPassword ? "text" : "password"}
                                value={newPassword}
                            />

                            <TextField
                                label="Confirmação da nova senha"
                                id="cpf-input"
                                sx={{ m: 1, width: "90%" }}
                                variant="standard"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() =>
                                                    setShowNewConfirmationPassword(
                                                        !showNewConfirmationPassword
                                                    )
                                                }
                                            >
                                                {showNewConfirmationPassword ? (
                                                    <FaEyeSlash />
                                                ) : (
                                                    <FaEye />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                type={showNewConfirmationPassword ? "text" : "password"}
                                onChange={(evt) => setNewConfirmationPassword(evt.target.value)}
                                value={newConfirmationPassword}
                            />
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsOpenChangePasswordModal(false)}>Fechar</Button>
                    <Button onClick={handleChangePassword}>Confirmar</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
